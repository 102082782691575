<template>
    <div id="children">
		<v-head currentIndex=4></v-head>
        <div class="banner-container">
            <div class="banner-content">
                <div class="c-h-test-form">
                    <div class="form-t">
                        <span :class="{'gender': true, 'man': true, 'checked': gender==1 }" @click="changeGender(1)">男孩</span>
                        <span :class="{'gender': true, 'woman': true, 'checked': gender==2 }" @click="changeGender(2)">女孩</span>
                        <div class="birthday-wrapper">
                            <input type="text" class="birthday" readonly placeholder="请選擇出生日期" @click="pickDate" v-model="birthday">
                            <a-date-picker placeholder="请選擇出生日期" @change="onBirthdayChanged" class="date-picker-box" ref="myBtn" format="YYYY-MM-DD"/>
                        </div>
                        <span :class="{'icon-gender': true, 'woman': gender==2}"></span>
                    </div>
                    <div class="form-row">
                        <div class="form-item">
                            <label class="form-item-label" for="form-input01">身高:</label>
                            <input type="number" id="form-input01" v-model="height" @blur="onBlur('height')">
                            <span class="hint">cm</span>
                        </div>
                        <div class="form-item">
                            <label class="form-item-label" for="form-input02">體重:</label>
                            <input type="number" id="form-input02" v-model="weight" @blur="onBlur('weight')">
                            <span class="hint">kg</span>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-item">
                            <label class="form-item-label" for="form-input03">頭圍:</label>
                            <input type="number" id="form-input03" v-model="head" @blur="onBlur('head')">
                            <span class="hint">cm</span>
                        </div>
                        <div class="form-item">
                            <label class="form-item-label" for="form-input04">胸圍:</label>
                            <input type="number" id="form-input04" v-model="chest" @blur="onBlur('chest')">
                            <span class="hint">cm</span>
                        </div>
                    </div>
                    <div class="btn-submit" @click="test"><div class="lds-ring" v-if="loading"><div></div><div></div><div></div><div></div></div>{{loading ? '正在提交...' : '生長發育水平測試'}}</div>
                </div>
                <div class="c-h-intro">
                    <h3 class="c-h-intro-title">關注兒童生長發育問題</h3>
                    <p class="c-h-intro-desc">生長發育是嬰幼兒及青少年期所特有的生理現象。但生長發育不是簡單的身體由小增大的過程，而是一個十分複雜的現象。 牽涉到個體細胞的增加、分化，器官結構及功能的完善。 </p>
                </div>
            </div>
        </div>
        <div class="children-growth-w">
            <div class="children-growth">
                <img src="../../../assets/imgs/pc/children/img_ertong@2x.png" alt="小兒生長發育">
                <div class="children-growth-intros">
                    <div class="children-growth-intro">
                        <h2>小兒生長發育總的特點</h2>
                        <p>出生後頭2年身高、體重增長較快；2歲至青春期以前有較為穩定的增加；青春期快速增長，以後漸漸停止。 </p>
                    </div>
                    <div class="children-growth-intro">
                        <h2>體格發育有頭尾規律</h2>
                        <p>嬰幼兒期頭部發育領先，隨著年齡的增長，頭增長不多而四肢、軀幹增長速度加快。 嬰兒期頭部高度占全身的1/4，成人頭高占身高的1/ </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="children-attribute-w">
            <router-view></router-view>
        </div>
        <children-test-result v-if="visible" :callback="onClose" :params="params" :testResult="testResult" />
		<v-foot/>
    </div>
</template>

<script>
	import vHead from '@/layouts/Head.vue'
	import vFoot from '@/layouts/Foot.vue'
    import ChildrenTestResult from '@/layouts/ChildrenTestResult.vue'
	export default {
        components: {
			vHead,
			vFoot,
            ChildrenTestResult
        },
        data(){
            return {
                gender: null,
                birthday: null,
                height: null,
                weight: null,
                head: null,
                chest: null,
                tabIndex: 1,
                style: '',
                visible: false,
                loading: false,
                params: {},
                testResult: {},
            }
        },
        methods: {
            onBlur: function(key) {
                var v = parseFloat(this[key])
                if(isNaN(v)) {
                    this[key] = ''
                } else {
                    this[key] = v
                }
            },
            onBirthdayChanged: function(date, dateString) {
                this.birthday = dateString
            },
            pickDate: function(){
                const elem = this.$refs.myBtn.$el.children[0].children[0]
                elem.click()
            },
            changeGender: function(v) {
                this.gender = v
            },
            changeTab: function(index) {
                this.tabIndex = index
            },
            test: function(){
                if(this.loading) return
                if(!this.gender) {
                    this._toast.warning(this, '請選擇性別')
                    return
                }
                if(!this.birthday) {
                    this._toast.warning(this, '請選擇出生日期')
                    return
                }
                this.loading = true
                var params = {
                    "birthday": this.birthday,
                    "chest": this.chest || 0,
                    "gender": this.gender,
                    "head": this.head || 0,
                    "height": this.height || 0,
                    "weight": this.weight || 0,
                }
                this.$store.dispatch('childTest', params)
                .then((rs)=>{
                    this.loading = false
                    this.params = params
                    this.testResult = rs
                    this.visible = true
                    this.style = document.body.getAttribute('style') || ''
                    document.body.setAttribute('style', this.style + 'overflow: hidden;')
                }).catch((error)=>{
                    this.loading = false
                    this._toast.warning(this, error || '操作失敗')
                })
            },
            onClose: function(){
                this.visible = false
                document.body.setAttribute('style', this.style)
            }
        }
    }
</script>

<style lang="scss" scoped>
 	#children {
	   width: 100%;
   	}
	   
    .banner-container {
        height: 352px;
        width: 100%;
        background-repeat: no-repeat;
        background-image: url(../../../assets/imgs/pc/children/img_banerert@2x.png);
        background-size: 100% 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .banner-container > .banner-content {
        width: 1000px;
        height: 100%;
        display: flex;
        flex-direction: row;
    }

    .banner-container > .banner-content > .c-h-test-form {
        height: 282px;
        width: 460px;
        margin-top: 40px;
        margin-right: 57px;
        background-color: rgba(4, 58, 61, 0.4);
    }

    .banner-container > .banner-content > .c-h-intro {
        display: flex;
        flex-direction: column;
    }

    .banner-container > .banner-content > .c-h-intro > .c-h-intro-title {
        font-size: 36px;
        line-height: 37px;
        color: #fff;
        font-weight: bold;
        margin-top: 93px;

    }

    .banner-container > .banner-content > .c-h-intro > .c-h-intro-desc {
        width: 482px;
        height: 64px;
        font-weight: 400;
        font-size: 16px;
        color: #fff;
        line-height: 16px;
        margin-top: 58px;
    }

    .c-h-test-form {
        width: 460px;
        height: 282px;
        display: flex;
        flex-direction: column;
    }

    .c-h-test-form > .form-item {
        display: flex;
        flex-direction: row;
    }

    .c-h-test-form > .form-t {
        display: flex;
        flex-direction: row;
        height: 58px;
        width: 100%;
        border-bottom: 1px solid #2DAB90;
        align-items: center;
        position: relative;
    }

    .c-h-test-form > .form-t > .gender {
        display: inline-block;
        width: 56px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        color: #36CCAD;
        font-weight: 400;
        font-size: 16px;
        background-color: transparent;
        border: 1px solid #2DAB90;
        cursor: pointer;
    }
    .c-h-test-form > .form-t > .gender.man {
        margin-left: 94px;
    }

    .c-h-test-form > .form-t > .gender.checked {
        background-color: #36CCAD;
        font-weight: bold;
        color: #fff;
    }

    .c-h-test-form > .form-t > .icon-gender {
        position: absolute;
        left: 35px;
        top: 50%;
        width: 34px;
        height: 34px;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-image: url(../../../assets/imgs/pc/children/img_boy@2x.png);
        background-size: 100% 100%;
    }

    .c-h-test-form > .form-t > .icon-gender.woman {
        background-image: url(../../../assets/imgs/pc/children/img_girl@2x.png);
    }

    .c-h-test-form > .form-t .birthday-wrapper {
        width: 183px;
        height: 32px;
        position: relative;
        margin-left: 46px;
    }

    .date-picker-box {
        position: absolute;
        z-index: -1;
        left: 0;
        top:0
    }

    .c-h-test-form > .form-t .birthday {
        display: inline-block;
        width: 169px;
        height: 32px;
        background-color: transparent;
        color: #fff;
        font-weight: bold;
        font-size: 16px;
        padding-left: 14px;
        border: 1px solid #2DAB90;
        position: relative;
        z-index: 10;
    }

    .c-h-test-form > .form-t .birthday-wrapper::before {
        content: '';
        position: absolute;
        right: 5px;
        top: 7px;
        background: url(../../../assets/imgs/pc/children/img_ril@2x.png) no-repeat;
        width: 20px;
        height: 20px;
        z-index: 1;
    }

    .c-h-test-form > .form-t .birthday::-webkit-calendar-picker-indicator {
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        display: block;
        background: url(../../../assets/imgs/pc/children/img_ril@2x.png) no-repeat;
        width: 20px;
        height: 20px;
        border-width: thin;
        position: absolute;
        right: 0;
        top: 6px;
        z-index: 10;
    }
    .c-h-test-form > .form-t .birthday::placeholder {
        color: #fff;
        font-weight: 400;
    }

    .c-h-test-form > .form-row {
        display: flex;
        flex-direction: row;
    }

    .c-h-test-form > .form-row > .form-item {
        display: flex;
        flex-direction: row;
        width: 230px;
        height: 32px;
        margin-top: 20px;
    }

    .c-h-test-form > .form-row > .form-item > label {
        height: 32px;
        line-height: 32px;
        font-weight: 400;
        font-size: 16px;
        color: #Fff;
        position: relative;
        margin-right: 12px;
        padding-left: 45px;
        display: inline-block;
    }

    .c-h-test-form > .form-row > .form-item > label::before {
        content: '';
        position: absolute;
        left: 24px;
        width: 17px;
        height: 15px;
        top: 9px;
        background-repeat: no-repeat;
        background-image: url(../../../assets/imgs/pc/children/img_sheng_w@2x.png);
        background-size: 100% 100%;
    }

    .c-h-test-form > .form-row:nth-child(2) > .form-item:nth-child(2) > label::before {
        background-image: url(../../../assets/imgs/pc/children/img_tiz_w@2x.png);
        width: 15px;
        height: 15px;
        left: 22px;
    }

    .c-h-test-form > .form-row:nth-child(3) > .form-item:nth-child(1) > label::before {
        background-image: url(../../../assets/imgs/pc/children/img_touw_w@2x.png);
        width: 18px;
        height: 16px;
        left: 23px;
    }

    .c-h-test-form > .form-row:nth-child(3) > .form-item:nth-child(2) > label::before {
        background-image: url(../../../assets/imgs/pc/children/img_xiongw_w@2x.png);
        width: 18px;
        height: 13px;
        left: 20px;
    }

    .c-h-test-form > .form-row > .form-item > input {
        background-color: transparent;
        display: inline-block;
        width: 83px;
        height: 32px;
        padding-left: 6px;
        padding-right: 6px;
        box-sizing: border-box;
        border: 1px solid #2DAB90;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
    }

    .c-h-test-form > .form-row > .form-item > .hint {
        color: #fff;
        height: 32px;
        line-height: 32px;
        font-weight: bold;
        font-size: 16px;
        margin-left: 8px;
    }

    .c-h-test-form > .btn-submit {
        width: 202px;
        height: 40px;
        text-align: center;
        color: #fff;
        border-radius: 8px;
        border: 1px solid #2DAB90;
        background-color: #36CCAD;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
        margin: 60px auto 0px auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        user-select: none;
    }
    .c-h-test-form > .btn-submit:active {
        opacity: 0.7;
    }

    .children-growth-w {
        height: 444px;
        width: 100%;
        background-color: #fff;
    }

    .children-growth {
        height: 444px;
        width: 1000px;
        margin: 0px auto;
        display: flex;
        flex-direction: row;
    }

    .children-growth > img {
        display: inline-block;
        height: 364px;
        vertical-align: middle;
        margin-top: 40px;
        margin-right: 73px;
    }

    .children-growth > .children-growth-intros,
    .children-growth .children-growth-intro {
        display: flex;
        flex-direction: column;
    }

    .children-growth .children-growth-intro:nth-child(1) {
        margin-top: 88px;
    }

    .children-growth .children-growth-intro:nth-child(2) {
        margin-top: 54px;
    }

    .children-growth .children-growth-intro > h2 {
        color: #231F20;
        font-size: 20px;
        font-weight: bold;
        line-height: 20px;
        height: 20px;
    }

    .children-growth .children-growth-intro > p {
        width: 358px;
        line-height: 22px;
        font-weight: 400;
        font-size: 16px;
        color: #636363;
        margin-top: 13px;
    }

    .children-attribute-w {
        width: 100%;
        min-height: 530px;
        background-color: #fff;
    }

    /** loading start */
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 32px;
        height: 32px;
    }
    
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 4px;
        border: 4px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /** loading end */ 
</style>